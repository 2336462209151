<template>
	<div class="login-page">
		<div class="a-logo-box">
			<img src="../static/images/logo.png">
		</div>
		<div class="login-form">
			<a-form-model ref="loginForm"  :model="loginParams" :rules="loginRules">
				<a-form-model-item prop="tel">
					<a-input v-model="loginParams.phone" size="large" type="text" placeholder="请输入账号">
						<a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
					</a-input>
				</a-form-model-item>
				<a-form-model-item prop="password">
					<a-input-password v-model="loginParams.password" size="large" placeholder="请输入密码">
						<a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
					</a-input-password>
				</a-form-model-item>
				<a-button @click="login" :loading="logining" class="login-btn" type="primary" size="large">登录</a-button>
			</a-form-model>
		</div>
	</div>
</template>
<script> 
	export default {
		data(){
			return {
				logining: false,
				loginParams:{
					phone:'',
					password:''
				},
				loginForm: this.$form.createForm(this, { name: 'loginForm' }),
				loginRules: {
					tell: [
						{ required: true, message: '填写账号', trigger: 'blur' }
					],
					password:[
						{ required: true, message: '填写密码', trigger: 'blur' }
					]
				}
			}
		},
		mounted() {
			
		},
		methods: {
			login() {
				let t = this;
				console.log(231231231)
				this.$refs.loginForm.validate(valid => {
					if(valid) {
						t.logining = true;
						t.$store.dispatch("login", t.loginParams).then(res => {
							let {code,data,msg} = res;
							console.log(res)
							if(code == 0) {
								t.$message.success("登录成功",1)
								t.$router.push({path: '/'})
							}else{
								t.$message.error(msg,1)
							}
							t.logining = false;
						}).catch(err => {
							t.logining = false;
						})
					}
				})
			}
		}
	}
</script>

<style lang="less">
	.login-page {
		width: 100vw;
		height: 100vh;
		background: #f0f2f5 url(../static/icon/login_bg.svg) no-repeat 50%;
		background-size: 100%;
		position: relative;
		.a-logo-box {
			margin: 0 auto;
			padding: 100px 0 50px;
			img {
				width: 200px;
				display: block;
				height: auto;
				margin: 0 auto;
			}
			h1 {
				font-size: 36px;
				text-align: center;
				margin-bottom: 0;
			}
		}
		.login-form {
			width: 400px;
			margin: 0 auto;
		}
		.login-btn {
			width: 100%;
		}
	}
</style>
